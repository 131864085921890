/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.App {
  height: calc(100vh - 120px);
  /* border: 1px solid red; */
}

.nutrition-label {
  width: 460px;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

.nutrition-label .border {
  width: 422px;
  padding: 16px;
  border: 3px solid black;
}

.nutrition-label .black {
  font-family: "HelveticaNeueBlackExtended", "HelveticaNeue-Black-Extended", "Helvetica Neue Black Extended", "HelveticaNeueBlack", "HelveticaNeue-Black", "Helvetica Neue Black", "HelveticaNeue", "Helvetica Neue", 'TeXGyreHerosBold', "Arial Black", sans-serif;
  font-weight: 800;
  font-stretch: expanded;
}

.nutrition-label .title {
  font-size: 50px;
  padding: 0;
  border: 0;
}

.nutrition-label .line {
  width: 100%;
  height: 1px;
  background-color: black;
  margin: 4px 0 4px 0;
}

.nutrition-label .line.heavy {
  height: 6px;
}

.nutrition-label .line.black {
  height: 16px;
}

.nutrition-label .row {
  display: flex;
  flex-direction: row;
}

.nutrition-label .row.space-between {
  justify-content: space-between;
}

.nutrition-label .servings {
  font-size: 20px;
}

.nutrition-label .serving-size {
  font-size: 20px;
}

.nutrition-label .amount-per-serving {
  font-size: 18px;
}

.nutrition-label .calories {
  align-items: flex-end;
  margin-top: -20px;
}

.nutrition-label .calories .text {
  font-size: 40px;
}

.nutrition-label .calories .value {
  font-size: 50px;
}

.nutrition-label .daily-value {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.nutrition-label .daily-value.asterix {
  justify-content: flex-start;
  flex-direction: row;
  flex-grow: 0;
  font-size: 12px;
  gap: 4px;
}

.nutrition-label .indent {
  padding-left: 32px;
}

.nutrition-label .nutrient {
  font-size: 16px;
}

.nutrition-label .nutrient .row {
  align-items: flex-end;
  gap: 8px;
}

.nutrition-label .nutrient .quantity {
  font-size: 14px;
}

.nutrition-label .ingredients {
  padding-top: 16px;
  font-size: 14px;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}